<template>
	<div class="bg-white h100">
		<header class="container-fluid px-0">
			<nav
				class="navbar navbar-expand-lg navbar-light bg-dark fixed-top"
				style="min-width: 260px; min-height: 68px">
				<div class="container">
					<a
						class="navbar-brand w-50 d-inline mr-0 pointer"
						:class="{ 'position-absolute': !['lg', 'xl'].includes(windowWidth)}"
						@click="$router.push({ name: getRedirectName('Store') })">
						<div
							:id="logoIds().navbarDark"
							class="my-1 float-left" /></a>
				</div>
			</nav>
		</header>
		<main class="mt-5 container h100">
			<router-view
				:key="$route.fullPath"
				class="pt-5" />
		</main>
		<footer
			id="about-us"
			class="aboutUsStore">
			<b-card
				class="border-0 rounded-0 bg-dark mb-0">
				<div
					class="row p-2 mx-auto mt-3 container">
					<div class="col">
						<div class="row mb-0">
							<div class="col-md-6 col-lg-5">
								<div
									class="row"
									:class="['xs', 'sm'].includes(windowWidth) ? 'mb-4 text-center' : 'mb-3'">
									<div class="col mb-2">
										<img
											class="img-fluid w-60"
											:src="`${S3_PATH}/assets/footer.png`">
									</div>
								</div>
							</div>
							<div class="col-md-6 col-lg-7 d-flex flex-column">
								<div class="row">
									<div class="col-md col-lg-auto ml-auto">
										<div
											:class="['xs', 'sm'].includes(windowWidth) ? 'text-center' : 'py-2'"
											class="text-white">
											<p class="px-0 mb-0 headline-small">
												{{ translate('support') }}
											</p>
											<hr
												:class="['xs', 'sm'].includes(windowWidth) ? 'd-none' : ''"
												class="border-white ml-0 mt-0 mb-0"
												style="width: 25px; border-width: 1px">
										</div>
										<div
											:class="['xs', 'sm'].includes(windowWidth) ? 'text-center mt-2 mb-4' : ''"
											class="text-transparency pt-2">
											<p class="text-white body-text mb-0">
												{{ support }}
											</p>
										</div>
									</div>
								</div>
							</div>
						</div>
						<hr
							class="border-transparency"
							style="border-width: 1px">
						<div
							:class="{ 'text-center' : ['xs', 'sm'].includes(windowWidth)}"
							class="row white-transparency font-weight-light">
							<div class="col-12 col-sm-4">
								<p class="captions">
									© {{ currentYear }} {{ companyName }} {{ translate('all_right_reserved') }}
									<br>{{ translate('footer_age') }}
								</p>
								<p
									v-if="ENTITY_MESSAGES_COUNTRIES.includes(country)"
									class="captions">
									<span class="white-transparency-title">{{ translate('payment_entity_title') }}</span>
									<br>{{ translate('payment_entity_text') }}
								</p>
							</div>
							<div class="col-12 col-sm-8 captions">
								{{ translate('disclaimer') }}
							</div>
						</div>
					</div>
				</div>
			</b-card>
		</footer>
	</div>
</template>

<script>
import { Store, Purchase } from '@/translations';
import { S3_PATH } from '@/settings/Images';
import WindowSizes from '@/mixins/WindowSizes';
import { ENTITY_MESSAGES_COUNTRIES } from '@/settings/Country';
import AgencySite from '@/mixins/AgencySite';

export default {
	name: 'PurchaseLayout',
	messages: [Store, Purchase],
	mixins: [WindowSizes, AgencySite],
	data() {
		return {
			S3_PATH,
			support: process.env.VUE_APP_COMPANY_EMAIL,
			currentYear: new Date().getFullYear(),
			companyName: process.env.VUE_APP_COMPANY_NAME,
			ENTITY_MESSAGES_COUNTRIES,
		};
	},
};
</script>

<style scoped>
	@import url('https://fonts.googleapis.com/css?family=Montserrat');
	p {
		font-family: 'Montserrat', sans-serif !important;
	}
	.aboutUsStore {
		font-family: 'Montserrat', sans-serif !important;
		font-weight: 600 !important;
	}
	body {
		min-height: 100% !important;
		height: 100% !important;
		background: white;
	}
	html {
		min-height: 100% !important;
		height: 100% !important;
	}
	.h100 {
		min-height: 80.5vh !important;
	}
	.white-transparency-title {
		color: #bdbdbd;
	}
	.white-transparency {
		color: #939393;
	}
	.text-transparency {
		color: #ffffff90;
	}
	.border-transparency {
		border-color: #6c6c6cbf;
	}
	.w-60 {
		max-width: 250px;
	}
	@media only screen and (max-width: 480px) {
		div#theme.Velovita #logo_white, div#theme.Velovita #jns_logo_white {
			width: auto;
			height: 30px;
		}
	}
</style>
